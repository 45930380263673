import { useModel } from "@stem-sims/nexus"
import { Col, Row } from "react-bootstrap"
import { useParams } from "react-router"
import { PulseLoader } from "react-spinners"
import { createSection, deleteSection, getTopic, updateSection, updateTopic } from "shared/routes/curriculum/books"
import EditingItemPane from "./EditingItemPane"
import EditingListPane from "./EditingListPane"
import React from "react"
import TopicStandards from "./TopicStandards"
import { ExclamationOctagonFill } from "react-bootstrap-icons"

export default function EditingTopic() {

    const { topicId } = useParams()

    const { loading: topicLoading, response: topic, refreshModel } = useModel({
        model: getTopic,
        props: { topicId }
    })

    const [defaultWeightSum, setDefaultWeightSum] = React.useState<number>(null)


    const onSubmitCallback = React.useCallback(async (values) => {
        await updateTopic({
            topicId: topicId,
            name: values.name,
            description: values.description,
            image: values.image,
            visible: values.visible,
            teacherOnly: values["teacher only"],
        })
        refreshModel()
    }, [topicId, refreshModel])

    const createSectionCallback = React.useCallback(async () => {
        await createSection({
            topicId: topicId,
        })
        refreshModel()
    }, [refreshModel, topicId])

    const updateSectionCallback = React.useCallback(async (values) => {
        await updateSection({
            sectionId: values.id,
            order: values.order,
        })
        refreshModel()
    }, [refreshModel])

    const deleteSectionCallback = React.useCallback(async (values) => {
        await deleteSection({ sectionId: values.id })
        refreshModel()
    }, [refreshModel])

    React.useEffect(() => {
        if (!topic) return
        const weightSum = topic.sections.reduce((acc, section) => {
            acc += section.defaultWeight
            return acc
        }, 0)
        setDefaultWeightSum(weightSum)
    }, [topic])


    if (!topic && topicLoading) {
        return <>
            <PulseLoader />
        </>
    }

    return <>
        {defaultWeightSum === 0 && <div className="d-flex justify-content-center text-center m-4 bg-danger text-white rounded p-2">
            <h5 className="fw-bold d-flex align-items-center m-0"><ExclamationOctagonFill size={35} /> <span className="ms-3">All sections in this topic have 0 section weights.</span> </h5>
        </div>}
        <h3>Topic: {topic.name}</h3>
        <Row>
            <Col>
                <EditingItemPane
                    data={{
                        ...topic,
                        "teacher only": topic.teacherOnly,
                    }}
                    fields={[
                        { fieldName: "name", inputType: "input" },
                        { fieldName: "description", inputType: "textarea" },
                        { fieldName: "image", inputType: "image" },
                        { fieldName: "visible", inputType: "checkbox" },
                        { fieldName: "teacher only", inputType: "checkbox" },
                    ]}
                    itemTypeName="topic"
                    onSubmit={onSubmitCallback}
                />
                <TopicStandards topicId={topicId} />
            </Col>

            <Col>
                <h4>Sections</h4>
                {!topic?.sections && topicLoading && <>
                    <PulseLoader />
                </>}

                {(topic?.sections || topicLoading) && <>
                    <EditingListPane
                        itemType="section"
                        items={topic?.sections ?? []}
                        createItem={createSectionCallback}
                        updateItem={updateSectionCallback}
                        deleteItem={deleteSectionCallback}
                    />
                </>}
            </Col>
        </Row>
    </>
}

