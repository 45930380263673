import CustomHTML from "components/CustomHTML";
import { editingOnly } from "components/Lessons/LessonModule/EditButton";
import { Button } from "react-bootstrap";
import styles from './assessment.module.scss'

export default function InlineTextEdit({ field, toggleEdit, saveEdit, fieldText, editMode }) {

    return (<>
        {editMode ? <>
            <textarea className={`form-control`} defaultValue={fieldText} id={`edit-question-text`} />
            <Button className={"m-1"} onClick={() => saveEdit(field)}>Save</Button>
            <Button className={"m-1"} onClick={() => toggleEdit(field)}>Cancel</Button>
        </>
            : <>
                <CustomHTML html={fieldText} />
                <button className={`${styles["edit-btn"]} ${editingOnly()}`} title="Edit Question" onClick={() => toggleEdit(field)} type="button">
                    <i className="fas fa-edit" />
                </button>
            </>}
    </>)
}
