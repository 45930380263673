import ToolTip from "components/General/ToolTip"
import { Stopwatch } from "react-bootstrap-icons"
import { AssignmentType } from "shared/types/assignmentTypes"
import { DateString } from "shared/types/commonTypes"
import { TeacherAssignmentResponse } from "shared/types/teacherTypes"
import Pane from "./General/Pane"
import { Col, Pagination, Row } from "react-bootstrap"
import  assignmentStyles from "../Dashboards/Student/Student.module.scss"
import { useNavigate } from "react-router"
import * as assignmentRoute from "shared/routes/assignments"
import { formatToLongDate } from "helpers/dateHelper"
import { useEffect, useState } from "react"

interface Props {
    ungradedSubmissions: UngradedSubmission[]
}

export type UngradedSubmission = {
    assignment: TeacherAssignmentResponse
    studentName: string
    submissionId: string
    submittedDate: DateString | Date
    assignmentType: AssignmentType
}

const PendingSubmissions = ({ ungradedSubmissions } : Props) => {
    const navigate = useNavigate()
    const SUBMISSIONS_PER_PAGE = 5
    const PAGES_PER_SET = 5
    
    const [paginatedSubmissions, setPaginatedSubmissions] = useState<UngradedSubmission[]>([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [pageRange, setPageRange] = useState<number[]>([])
    const [pageRangeNumber, setPageRangeNumber] = useState<number>(1)
    const [totalPages, setTotalPages] = useState<number[]>([])

    const navigateToSubmission = async (submission: UngradedSubmission) => {
        const submissionsResponse = (await assignmentRoute.getGrades({ assignmentId: submission.assignment.id })).data.filter(sub => sub.status === "submitted")
        const currentIndex = submissionsResponse.findIndex(res => res.submission.id === submission.submissionId)

        navigate("/dashboard/teacher/assignments/grading/submission", {
            state: {
                submissions: submissionsResponse,
                assignment: submission.assignment,
                currentIndex: currentIndex
            }
        })
    }

    /** Calculate the number of pages based on the the list of ungraded submissions */
    useEffect(() => {
        const totalPages: number = Math.ceil((ungradedSubmissions.length ?? 0) / SUBMISSIONS_PER_PAGE)
        const totalPagesArr: number[] = new Array(totalPages).fill(0).map((_, i) => i + 1)
        setTotalPages(totalPagesArr)
    }, [ungradedSubmissions])
    
    /** Slice the original submissions array into 5 submissions for each page */
    useEffect(() => {
        const start = (currentPage - 1) * SUBMISSIONS_PER_PAGE
        const end = start + SUBMISSIONS_PER_PAGE
        setPaginatedSubmissions(ungradedSubmissions?.slice(start, end))
    }, [currentPage, ungradedSubmissions])
    
    /** Calculate which pages should show in one set */
    useEffect(() => {
        const start = (pageRangeNumber - 1 ) * PAGES_PER_SET
        const end = start + PAGES_PER_SET

        const currentPageRange = totalPages?.slice(start, end)
        setPageRange(currentPageRange)
        setCurrentPage(currentPageRange[0]) // select the first page as current page out of the page set
    }, [pageRangeNumber, totalPages])

    if (ungradedSubmissions.length === 0) return null

  return (
    <div className="p-2 mb-3">
        <h3 className="text-start ms-3 fw-bold mb-3">
            <Stopwatch className="me-2 text-primary" />
            Submissions to grade
        </h3>
        {paginatedSubmissions.map((submission, index) => {
            const isTopic = submission.assignmentType === "topic"
            const isLesson = submission.assignmentType === "lesson"

            return (
                <ToolTip title="Needs Grading" key={`${submission.submissionId}-${index}`}>
                    <div onClick={() => navigateToSubmission(submission)}>
                        <Pane className={`shadow-sm mt-2 ${assignmentStyles.assignmentOverview}`}>
                            <Row>
                                <Col className="text-start" sm={12} md={7}>
                                    <p className="fw-bold">{submission.assignment.title}</p>
                                    <p className="text-muted fw-bold">{submission.studentName}</p>
                                </Col>
                                <Col className={`text-start`} sm={12} md={5}>
                                    <p><span className="fw-bold">submitted:</span> {formatToLongDate(submission.submittedDate as DateString)}</p>
                                    <p className={`rounded px-2 py-1 text-start d-inline ${isTopic ? assignmentStyles.labelTagTopic : isLesson ? assignmentStyles.labelTagLesson : assignmentStyles.labelTagAssessment}`}>{isTopic ? "topic" : isLesson ? "lesson" : "assessment"} submission</p>
                                </Col>
                            </Row>
                        </Pane>
                    </div>
                </ToolTip>
            )
        })}
        {totalPages.length > 1 && 
            <Pagination className="mt-3">
                {totalPages.length > PAGES_PER_SET && 
                    <Pagination.Prev disabled={pageRangeNumber === 1} onClick={() => setPageRangeNumber(prev => prev - 1)} className="me-2" />}

                {pageRange?.map((pageNumber) => {
                    return (
                        <Pagination.Item
                            className="me-2"
                            onClick={() => setCurrentPage(pageNumber)}
                            key={pageNumber}
                            active={pageNumber === currentPage}
                            data-testid={`page-${pageNumber}`}
                            >
                            {pageNumber}
                        </Pagination.Item>
                    )
                })}

                {totalPages.length > PAGES_PER_SET && 
                    <Pagination.Next disabled={pageRangeNumber === (Math.ceil(totalPages.length / PAGES_PER_SET))} onClick={() => setPageRangeNumber(prev => prev + 1)} />}
            </Pagination>
        }
    </div>
  )
}

export default PendingSubmissions
