import { createContext } from "react"
import { DrawingAnswer } from "shared/types/studentTypes"

export type AssignmentTopicContextType = {
    lessonDrawResponses?: Record<string, DrawingAnswer>
    setLessonDrawResponses?: React.Dispatch<React.SetStateAction<Record<string, DrawingAnswer>>>
    assessmentDrawResponses?: Record<string, DrawingAnswer>
    setAssessmentDrawResponses?: React.Dispatch<React.SetStateAction<Record<string, DrawingAnswer>>>
    refreshTopicSectionProgressCheck: () => void
}

export const AssignmentTopicContext = createContext<AssignmentTopicContextType>({
   lessonDrawResponses: null,
   setLessonDrawResponses: null,
   assessmentDrawResponses: null,
   setAssessmentDrawResponses: null,
   refreshTopicSectionProgressCheck: null,
})
