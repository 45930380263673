import { useContext } from "react"
import styles from "./general.module.scss"
import { AuthContext } from "AuthContext"
import { DUE_SUBMISSIONS_PENDING, NO_SUBMISSIONS, SUBMISSIONS_PENDING } from "shared/types/teacherTypes"

const ColorLegend = ({ colors } : { colors: string[] }) => {
    const { isStudent } = useContext(AuthContext)
    
  return (
    <div className="container rounded p-3">
        <div className={`row ${styles.gridContainer}`}>
            {colors.map((color, i) => (
                <div className={`col col-12 col-lg-6 d-flex align-items-center ${styles.item}`} key={`${color}-${i}`}>
                    <div className={`${styles.colorLegendBox} 
                        ${(color === "Not Started" || color === NO_SUBMISSIONS) ? styles.noSubmission : 
                            (color === "Started" || color === SUBMISSIONS_PENDING) ? styles.submissions : 
                            (color === "Due, Started" || color === DUE_SUBMISSIONS_PENDING) ? styles.submissionsDue : styles.noSubmissionDue}`}>
                    </div>
                    <span className={styles.colorLegendText}>{ isStudent ? color : color }</span>
                </div>
            ))}
        </div>
    </div>
  )
}

export default ColorLegend
