import axios from "axios"
import { GetGradesProps, ApiResponse, GetSubmissionsProps,
    GetSubmissionsData, Submission, GetSubmissionProps,
    PostSubmissionsData, PostSubmissionsProps, GetGradesData } from "../types/assignmentTypes"
import { StringBoolean } from "shared/types/commonTypes"

export async function getGrades({ assignmentId }: GetGradesProps): Promise<ApiResponse<GetGradesData>> {
    const response = await axios.get(`/api/assignments/${assignmentId}/grades`)
    return response.data
}

export async function getSubmissions({ assignmentId }: GetSubmissionsProps): Promise<ApiResponse<GetSubmissionsData>> {
    const response = await axios.get(`/api/assignments/${assignmentId}/submissions`)
    return response.data
}

export async function getSubmission({ submissionId }: GetSubmissionProps): Promise<ApiResponse<Submission>> {
    const response = await axios.get(`/api/submissions/${submissionId}`)
    return response.data
}

export type AssessmentQuestion = {
    type: string
    number: number
    question: string
    correctAnswer: string
    isDrawing: StringBoolean
    answers: {
        letter: string
        answer: string
        imageExists: boolean
        image: {
            letter: string
            update: string
            altText: string
        }
    }[]
    image: {
        number: number
        update: string
        altText: string
    }
    imageExists: boolean
}

export async function postSubmissions({ assignmentId }: PostSubmissionsProps): Promise<ApiResponse<PostSubmissionsData>> {
    const response = await axios.post(`/api/assignments/${assignmentId}/submissions`)
    return response.data
}

export async function getAssessmentQuestions({ assessmentId }: { assessmentId: string }): Promise<AssessmentQuestion[]> {
    const response: ApiResponse<AssessmentQuestion[]> = await axios.get(`/api/assessments/${assessmentId}/questions`)
    return response.data
}
export async function updateAssessmentQuestion({assessmentId, ...body}): Promise<string> {
    const response: ApiResponse<string> = await axios.put(`/api/assessments/${assessmentId}/question`, body)
    return response.data
}
export async function deleteAssessmentQuestion({assessmentId, ...body}): Promise<string> {
    const response: ApiResponse<string> = await axios.delete(`/api/assessments/${assessmentId}/question`, { data: body })
    return response.data
}

const assignments = {
    getGrades,
    getSubmissions,
    postSubmissions,
    getSubmission,
    getAssessmentQuestions,
    updateAssessmentQuestion,
    deleteAssessmentQuestion
}

export default assignments
