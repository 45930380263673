export default function fillTableData() {
    const tableContainer = document.getElementById("table-data")
    const tableObject = {}
    if (tableContainer) {
        const dataInfo = tableContainer.getAttribute("data-info")
        const tableData = dataInfo ? JSON.parse(tableContainer.getAttribute("data-info")) : []

        for (let dataIndex = 0; dataIndex < tableData.length; dataIndex++) {
            const submissionData = tableData[dataIndex]
            tableObject[submissionData.ID] = submissionData.Response
        }
        const tableElements = tableContainer.getElementsByTagName("td")
        const existingInputs = document.querySelectorAll(".lessonTable input, .lessonTable select, .lessonTable textarea")
        let inputCount = 1

        for (let index = 0; index < tableElements.length; index++) {
            const tableElement = tableElements[index]

            if (tableElement.children.length === 0) {
                const response = tableObject[inputCount]

                if (response !== null) {
                    tableElement.textContent = response
                }

                inputCount++
            }
        }
        for (let lastIndex = 0; lastIndex < existingInputs.length; lastIndex++) {
            const existingInput: any = existingInputs[lastIndex]
            const dataResponse = tableObject[inputCount]
    
            existingInput.style.pointerEvents = "none"
    
            if (dataResponse !== null) {
                if (existingInput.type === "checkbox" && dataResponse === "on") {
                    existingInput.checked = true
                } else if (existingInput.tagName === "SELECT") {
                    existingInput.value = dataResponse
                }
    
                inputCount++
            }
        }
    }
}
