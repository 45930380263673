import { AuthContext } from "AuthContext"
import React, { useState } from "react"
import { toast } from "react-toastify"
import { Field } from "formik"
import Image from "react-bootstrap/Image"
import assignmentsModel, { AssessmentQuestion as AssessmentQuestionType } from "shared/routes/assignments"
import { sanitizePath } from "shared/helpers/moduleHelper"
import EditButton from "components/Lessons/LessonModule/EditButton"
import styles from './assessment.module.scss'
import CustomHTML from "components/CustomHTML"
import DrawingQuestion from "./components/DrawingQuestion"
import DrawingImageButton from "pages/Dashboard/Teacher/Grades/Submission/components/DrawingImageButton"
import { AssignmentTopicContext } from "./AssignmentTopicContext"
import { DrawingAnswer } from "shared/types/studentTypes"
import InlineTextEdit from "./InlineTextEdit"

interface AssessmentQuestionProps {
    question: AssessmentQuestionType
    index: number
    saveAssessment: () => void
    initialAnswerData: string | DrawingAnswer
    isPartOfTopic: boolean
    setDrawingResponses: React.Dispatch<React.SetStateAction<Record<string, DrawingAnswer>>>
    assessmentId: string
    moduleName: string
    type: string
    assessmentNumber: number
    refreshQuestions: () => void
}

export default function AssessmentQuestion(props: AssessmentQuestionProps) {
    const { question, index, saveAssessment, initialAnswerData, isPartOfTopic, setDrawingResponses, assessmentId, moduleName, type, assessmentNumber, refreshQuestions } = props
    const authInformation = React.useContext(AuthContext)
    const { setAssessmentDrawResponses, refreshTopicSectionProgressCheck } = React.useContext(AssignmentTopicContext)
    const [editingQuestionTextNumber, setEditingQuestionTextNumber] = useState(0)
    const editing = process.env.REACT_APP_EDITING === "true"

    const getImagePath = (questionNumber: number) => {
        const imagePath = `/simulations/${sanitizePath(moduleName)}/assessment/${type.toLowerCase()}-assessment-${assessmentNumber}-question-${questionNumber}`
        return imagePath
    }

    const getQuestionImagePath = (questionNumber: number) => {
        const questionImagePath = `${getImagePath(questionNumber)}.png`
        return questionImagePath
    }

    const getAnswerImagePath = (questionNumber: number, answerLetter: string) => {
        const answerImagePath = `${getImagePath(questionNumber)}-answer-${answerLetter.toLowerCase()}.png`
        return answerImagePath
    }

    const editCorrectAnswer = async (question, values) => {
        await assignmentsModel.updateAssessmentQuestion({
            assessmentId: assessmentId,
            questionNumber: question.number,
            correctAnswer: values.correctAnswer
        })
        refreshQuestions()
    }

    const toggleQuestionTextEdit = (question) => {
        const currentlyEditing = editingQuestionTextNumber === question.number
        setEditingQuestionTextNumber(currentlyEditing ? 0 : question.number)
    }

    const saveQuestionText = (question) => {
        const questionText = document.querySelector(`#edit-question-text`)["value"]
        const questionChanged = question.question !== questionText
        if (questionChanged) {
            assignmentsModel.updateAssessmentQuestion({
                assessmentId: assessmentId,
                questionNumber: question.number,
                questionText: questionText,
            }).then(() => {
                toast.success("Question text saved successfully.")
                refreshQuestions()
            }).catch((err) => {
                toast.error(err.response?.data?.message ?? "There was an error saving the question text. Please try again.")
            })
        }
        setEditingQuestionTextNumber(-1)
    }

    const deleteQuestion = async (questionNumber) => {
        await assignmentsModel.deleteAssessmentQuestion({
            assessmentId: assessmentId,
            questionNumber: questionNumber,
        })
        refreshQuestions()
    }

    return (
        <div key={index} className={`container ${styles.question} p-3`}>
            <div className={`${styles.text}`}>
                <span className="pe-2">{index + 1}.</span>
                <InlineTextEdit field={question} toggleEdit={toggleQuestionTextEdit} saveEdit={saveQuestionText} editMode={editingQuestionTextNumber === question.number} fieldText={question.question} />
            </div>
            {(question.imageExists && question.isDrawing === "No") && (
                <Image src={getQuestionImagePath(question.number)} alt={question.image.altText || 'Assessment Image'} className="rounded mb-2" fluid />
            )}
            <div id={`${index + 1}`} className="question-group d-flex flex-column align-items-start">
                {question.isDrawing === "No" && question.answers.map((answer, i) => (
                    <div key={`${i}-${answer.letter === initialAnswerData}`} className={styles.answerOption + " text-start"}>
                        <input
                            type="radio"
                            onChange={() => {
                                refreshTopicSectionProgressCheck?.()
                                saveAssessment?.()
                            }}
                            id={`q${index}a${i}`}
                            name={`question-${question.number}`}
                            defaultChecked={answer.letter === initialAnswerData}
                            value={answer.letter}
                            data-q-no={index + 1} />
                        {answer.imageExists ?
                            <Image src={`${getAnswerImagePath(question.number, answer.letter)}`} alt={question.image.altText || 'Assessment Image'} fluid />
                            :
                            <label htmlFor={`q${index}a${i}`} className="d-inline ms-1">
                                <span>{answer.letter}. </span><CustomHTML html={answer.answer} />
                            </label>
                        }
                    </div>
                ))}
            </div>
            {question.isDrawing === "Yes" &&
                <DrawingQuestion
                    key={"XXX"}
                    initialAssessmentDrawingData={initialAnswerData as DrawingAnswer}
                    setAssessmentDrawingResponse={isPartOfTopic ? setAssessmentDrawResponses : setDrawingResponses}
                    questionType="assessment"
                    questionNumber={question.number}
                    questionImage={question.imageExists ? `${getQuestionImagePath(question.number)}` : null}
                    autoSave={saveAssessment} />
            }
            {(editing || (authInformation.isLoggedIn && !authInformation.isStudent)) && (
                question.isDrawing === "Yes" ?
                    <div>
                        {question?.answers[0]?.imageExists &&
                            <>
                                <DrawingImageButton
                                    drawingUrl={getAnswerImagePath(question.number, "A")}
                                    title="Example Drawing"
                                    optionalAnswer={question.correctAnswer} />
                            </>}
                        {question?.answers[0] && !question?.answers[0]?.imageExists &&
                            <>
                                <div className={`text-success fw-bold ${styles.correctAnswer} mt-2`}>
                                    Correct Drawing Answer: {question?.answers[0]?.answer}
                                </div>

                            </>}
                    </div>
                    :
                    <div className={`text-success fw-bold ${styles.correctAnswer} mt-2`}>
                        Correct Answer: {question.correctAnswer}
                    </div>)}
            <EditButton text="Remove Question" editCallback={() => deleteQuestion(question.number)}>
                <p>Are you sure you would like to remove this assessment question?</p>
            </EditButton>
            <EditButton text="Edit Correct Answer" editCallback={(values) => editCorrectAnswer(question, values)}>
                <div className="mb-4">
                    <h2 className="h5">
                        Question #{question.number} Correct Answer
                    </h2>
                    <Field
                        as={'textarea'}
                        name={"correctAnswer"}
                        defaultValue={question.correctAnswer}
                        className={`lesson-input w-75`}
                        disabled={question.isDrawing === "Yes"}
                    />
                    {question.isDrawing === "Yes" &&
                        <p className="text-muted">Drawing questions should only have one correct answer.</p>}
                </div>
            </EditButton>
        </div>
    )
}
