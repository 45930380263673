import Pane from "components/Dashboards/General/Pane"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { useNavigate } from "react-router"
import styles from "./editingStyles.module.scss"
import FAIcon from "components/General/FAIcon"
import { useEffect, useState } from "react"
import titleCase from "helpers/titleCase"
import { CurriculumSectionWithGradable } from "shared/types/curriculumTypes"
import { ExclamationOctagonFill } from "react-bootstrap-icons"

const { selectable } = styles

interface BasicItemInfo {
    id: string
    name?: string
    header?: string
}

interface SectionInfo extends BasicItemInfo {
    sections: CurriculumSectionWithGradable[]
}

function hasSections(item: BasicItemInfo): item is SectionInfo {
    return (item as SectionInfo).sections !== undefined
}

interface Props<T> {
    items: Array<T>
    itemType: "unit" | "topic" | "section"
    createItem: () => Promise<any>
    updateItem: (values: { id: string, order: number }) => Promise<any>
    deleteItem: (values: { id: string }) => Promise<any>
}

export default function EditingListPane<T extends BasicItemInfo>({ itemType, items, createItem, updateItem, deleteItem }: Props<T>) {
    const navigate = useNavigate()

    const [modal, setModal] = useState(null)
    const [deleteModalState, setDeleteModalState] = useState<{ id: string, order: number, name: string }>({ id: null, order: null, name: null })

    const [noWeightTopicIds, setNoWeightTopicIds] = useState<string[]>([])

    useEffect(() => {
        if (itemType !== "topic") return
        items.forEach(topic => {
            if (!hasSections(topic)) return
            const weightSum = topic.sections.reduce((prev, curr) => {
                prev += curr.defaultWeight
                return prev
            }, 0)
            if (weightSum === 0) setNoWeightTopicIds(prev => [...prev, topic.id])
        })
    }, [items, itemType])

    return <>
        {items.map((item, index) => (
            <Row className="my-2" key={item.id}>
                <Col onClick={() => navigate(`${itemType}/${item.id}`)}>
                    <Pane className={selectable}>
                        <div>{item.name ?? item.header} {itemType === "topic" && noWeightTopicIds?.includes(item.id) && <span className="d-inline-block bg-danger p-1 text-white rounded"><ExclamationOctagonFill className="me-1" />  no weights</span>}</div>
                    </Pane>
                </Col>

                            <Col xs="auto">
                                <Button disabled={index === 0} onClick={async () => {
                                    updateItem({
                                        id: item.id,
                                        order: index
                                    })
                                }}><FAIcon iconName={"sort-up"} /></Button>
                            </Col>

                            <Col xs="auto">
                                <Button disabled={index === items.length - 1} onClick={async () => {
                                    updateItem({
                                        id: item.id,
                                        order: index + 2
                                    })
                                }}
                                ><FAIcon iconName={"sort-down"} /></Button>
                            </Col>

                            <Col xs="auto" onClick={() => {
                                setModal("deleteItem")
                                setDeleteModalState({
                                    id: item.id,
                                    name: item.name ?? item.header,
                                    order: index + 1,
                                })
                            }}>
                                <Button variant="danger"><FAIcon iconName={"skull"} /></Button>
                            </Col>
            </Row>
        ))}

        <Row>
            <Col>
                <Button onClick={createItem}><FAIcon iconName="plus" /></Button>
            </Col>
        </Row>

        <Modal show={modal === "deleteItem"} onHide={() => {
            setModal(null)
            setDeleteModalState({
                id: null,
                name: null,
                order: null
            })
        }}>
            <Modal.Header closeButton>
                Delete {titleCase(itemType)}
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <h3>Are you sure you wish to delete {titleCase(itemType)} #{deleteModalState.order}, {deleteModalState.name}?</h3>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="danger" onClick={async () => {
                    await deleteItem({ id: deleteModalState.id })
                    setDeleteModalState({
                        id: null,
                        name: null,
                        order: null
                    })
                    setModal(null)
                }}>Delete</Button>
            </Modal.Footer>
        </Modal>
    </>
}
