import ReactDOM from "react-dom";

export default function loadTables(setFieldValue) {
	const wholeTables = document.getElementsByTagName("table")
	let existingInputs = document.querySelectorAll(".lessonTable input, .lessonTable select, .lessonTable textarea");
	let inputCount = 1;
	let tableData = {}
	var tableContainer = document.getElementById("table-container"); // are there any html templates with table container??
	let data = tableContainer?.getAttribute("data-info");
	if (data) {
		tableData = JSON.parse(data);
	}

	for (let i = 0; i < wholeTables.length; i++) {
		const tdArray = wholeTables[i]?.getElementsByTagName("td")
		for (let tdIndex = 0; tdIndex < tdArray.length; tdIndex++) {
			let tdElement = tdArray[tdIndex]
			if (tdElement.children.length === 0)
				{
					var input = document.createElement("input")
					
					input.setAttribute("type", "text")
					var boundingBox = tdElement.getBoundingClientRect()
					var height = boundingBox.height + "px"
					tdElement.style.height = height
					
					tdElement.style.padding = "0px"
					ReactDOM.render(<input data-table-inputs={`table-${i+1}-${inputCount}`} name={`table-${inputCount}`} onChange={(e) => {
						setFieldValue(e.target.name,e.currentTarget.value)
					}
					} />, tdElement)
					inputCount++
				}
		}
	}
	
	for (var lastIndex = 0; lastIndex < existingInputs.length; lastIndex++)
	{
		var existingInput = existingInputs[lastIndex];
		
		existingInputs[lastIndex].setAttribute("name", "table-" + inputCount);
		
		if (tableData !== null && tableData.hasOwnProperty(inputCount))
		{
			var dataResponse = tableData[inputCount];
			
			if (existingInput['type'] === "checkbox" && dataResponse === "on")
			{
				existingInput['checked'] = true;
			}
			
			else if (existingInput.tagName === "SELECT")
			{
				existingInput['value'] = dataResponse;
			}
		}
		
		inputCount++;
	}
}
